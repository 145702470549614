import { Suspense, useRef, useState } from 'react';

import { useLocation } from '@remix-run/react';

import moment from 'moment';

import { AddressInput } from '@shared/address-input';
import { ContactMethods } from '@shared/contact-methods.tsx';
import { InputBlock } from '@shared/input-block';
import { MultiSelectSearch } from '@shared/multi-select-search';
import Required from '@shared/required';
import { SelectSearch } from '@shared/select-search';
import { Error, Fields, MainBtn, Note, SecondaryBtn, SpanLabel } from '@shared/styled';
import { TextareaBlock } from '@shared/textarea-block';

import { useAuthedRequest } from '~/hooks/use-authed-request';
import { useBallTypeList } from '~/hooks/use-ball-type-list';
import { useDivisionList } from '~/hooks/use-division-list';
import { useOrganizationList } from '~/hooks/use-organization-list';
import { useUserInfo } from '~/hooks/use-user-info';

import type { Event, FormState } from '~/types';
import { addHttps } from '~/utils/add-https';
import { event as GAEvent } from '~/utils/gtags.client';
import { isValidContactMethod } from '~/utils/has-valid-contact-method';
import { stripHttps } from '~/utils/strip-https';
import { validateEmail } from '~/utils/validate-email';
import { validateUrl } from '~/utils/validate-url';

import styles from './form.module.css';

import { ImageUpload } from '../shared/image-upload';
import { MarkdownEditor } from '../shared/markdown-editor.client';
import { DateRangeInput } from './components/date-range-input';
import { DayPicker } from './components/day-picker';
import { Checkbox } from '../shared/checkbox';
import { ClientOnly } from '../client-only';

const defaultState: FormState = {
  address: null,
  contactConsent: false,
  marketingConsent: false,
  name: '',
  website: '',
  type: 'tournament',
  contactMethods: [{ type: 'email', value: '' }],
  creatorEmail: '',
  organizationId: undefined,
  signupUrl: '',
  description: '',
  divisions: [],
  ballType: [],
  weekDays: [],
  timezone: '',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
};

export const EventForm = ({
  event,
  initialData,
  close,
  successCallback,
  setShowOrgForm,
  handleSave,
}: {
  close: () => void;
  setShowOrgForm?: () => void;
  successCallback?: () => void;
  initialData?: Partial<Event> | null;
  event?: Event | null;
  handleSave: ((formState: Partial<FormState>, callback?: (() => void) | undefined) => void) | undefined;
}) => {
  const { pathname } = useLocation();
  const isFromOrg = pathname.includes('account/organizations');
  const { divisionList } = useDivisionList();
  const { organizationList } = useOrganizationList();
  const { ballTypeList } = useBallTypeList();
  const userInfo = useUserInfo();
  const isEditing = !!event;
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { post } = useAuthedRequest(false);
  const [formState, setFormState] = useState<FormState>(
    event
      ? {
          ...event,
          ...(event?.originalStartDate ? { startDate: event.originalStartDate } : {}),
          ...(event?.originalEndDate ? { endDate: event.originalEndDate } : {}),
        }
      : { ...defaultState, ...initialData, creatorEmail: userInfo?.email || '' },
  );
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const {
    streamUrl,
    address,
    name,
    website,
    type,
    divisions,
    startDate,
    endDate,
    contactMethods,
    signupUrl,
    ballType,
    creatorEmail,
    organizationId,
    coHostOrganizationId,
    description,
    weekDays,
    image,
    contactConsent,
    marketingConsent,
  } = formState;

  const endSameStartDate = startDate === endDate;

  const handleSubmit = async () => {
    if (validateForm()) {
      const newFormState: Partial<FormState> = hasChanged.current.reduce(
        (acc, key) => ({ ...acc, [key]: formState[key as keyof FormState] }),
        {},
      );
      let newImage = image;
      if (selectedFile) {
        newImage = await handleUpload();
      }

      const validContactMethods =
        (isEditing ? newFormState : formState)?.contactMethods?.filter(isValidContactMethod) || [];
      handleSave?.(
        isEditing
          ? {
              ...newFormState,
              id: event.id,
              organizationId,
              coHostOrganizationId,
              image: newImage,
              contactConsent,
              marketingConsent,
              ...(newFormState.contactMethods ? { contactMethods: validContactMethods } : {}),
            }
          : { ...formState, image: newImage, contactMethods: validContactMethods },
        () => {
          if (!isEditing) {
            successCallback?.();
            setFormState({ ...defaultState, creatorEmail: formState.creatorEmail });
            setFormErrors({});
            return;
          }
          close();
        },
      );
      GAEvent(isEditing ? 'submit_edit_event_form' : 'submit_add_event_form');
    } else {
      GAEvent({
        action: isEditing ? 'submit_edit_event_form_error' : 'submit_add_event_form_error',
        value: JSON.stringify({ ...formErrors }),
      });
      setTimeout(() => {
        Array.from(document?.querySelectorAll('.error-field'))?.[0]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });
    }
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    if (!name) {
      errors.name = 'Name is required.';
    }

    if (!address) {
      errors.address = 'Address is required.';
    }

    if (!type) {
      errors.type = 'Please choose a type.';
    }

    const validContactMethods = contactMethods?.filter(isValidContactMethod) || [];
    if (!validContactMethods.length) {
      errors.contactMethods = 'Please enter a preferred contact method.';
    }

    if (!divisions?.length) {
      errors.divisions = 'Please choose at least one division.';
    }

    if (!ballType?.length) {
      errors.ballType = 'Please choose at least one ball type.';
    }

    if (!startDate) {
      errors.startDate = 'Please choose a start date.';
    }

    if (!endDate) {
      errors.endDate = 'Please choose an end date.';
    }

    if (type && !endSameStartDate && ['league', 'drop-in', 'practice', 'clinic'].includes(type) && !weekDays?.length) {
      errors.weekDays = 'Please choose at least one week day.';
    }

    if (!userInfo?.email) {
      if (!creatorEmail) {
        errors.creatorEmail = 'Please enter your email.';
      } else if (!validateEmail(creatorEmail)) {
        errors.creatorEmail = 'Please enter a valid email.';
      }
    }

    if (website && !validateUrl(website)) {
      errors.website = 'Please enter a valid URL.';
    }

    if (streamUrl && !validateUrl(streamUrl)) {
      errors.streamUrl = 'Please enter a valid URL.';
    }

    if (signupUrl && !validateUrl(signupUrl)) {
      errors.signupUrl = 'Please enter a valid URL.';
    }

    if (!userInfo?.email && !contactConsent) {
      errors.contactConsent = 'You must consent to being contacted in case your event has problems.';
    }

    setFormErrors(errors);

    return !Object.keys(errors).length;
  };

  const hasChanged = useRef<string[]>([]);

  const handleUpload = async () => {
    if (!selectedFile) {
      return null;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const { data } = await post('upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setSelectedFile(null);
      setUploading(false);
      return data as Event['image'];
      // Optionally, you can do something with the response from the server
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploading(false);
      return;
      // Handle error: display an error message or perform other actions
    }
  };

  const handleFormChange = (newVal: Partial<FormState>) => {
    if (isEditing) {
      Object.keys(newVal).forEach((key) => {
        const castedKey = key as keyof FormState;
        if (
          (castedKey === 'address' && event.address?.id !== newVal.address?.id) ||
          (castedKey !== 'address' &&
            castedKey !== 'contactConsent' &&
            castedKey !== 'marketingConsent' &&
            JSON.stringify(event[castedKey]) !== JSON.stringify(newVal[castedKey]))
        ) {
          if (!hasChanged.current.includes(castedKey)) {
            hasChanged.current = [...hasChanged.current, castedKey];
          }
        } else {
          hasChanged.current = hasChanged.current.filter((v) => v !== castedKey);
        }
      });
    }

    setFormState({ ...formState, ...newVal });
  };

  return (
    <>
      <Fields>
        {!userInfo?.email && (
          <div className={`${styles.FieldWrapper} ${formErrors.creatorEmail ? 'error-field' : ''} ${styles.fullWidth}`}>
            <InputBlock
              required
              label="Your email"
              note="We'll only use this if there is a problem with your event or to give you updates. It will not be visible."
              type="string"
              value={creatorEmail}
              updateValue={(newVal) => handleFormChange({ creatorEmail: newVal })}
            />
            {formErrors.creatorEmail && <Error>{formErrors.creatorEmail}</Error>}
          </div>
        )}
        <div className={`${styles.FieldWrapper} ${formErrors.name ? 'error-field' : ''}`}>
          <InputBlock
            label="Event Name"
            required
            type="string"
            value={name}
            updateValue={(newVal) => handleFormChange({ name: newVal })}
          />
          {formErrors.name && <Error>{formErrors.name}</Error>}
        </div>
        {userInfo?.email && (
          <div className={styles.FieldWrapper}>
            <SpanLabel>Event Image</SpanLabel>
            <ImageUpload
              originalFileUrl={image?.fileName && `https://d816ski4r95b0.cloudfront.net/${image?.fileName}`}
              onChange={setSelectedFile}
              image
              deleteImage={() => {
                setSelectedFile(null);
                setFormState((prev) => ({ ...prev, image: null }));
              }}
            />
          </div>
        )}
        <div className={`${styles.FieldWrapper} ${formErrors.type ? 'error-field' : ''}`}>
          <SelectSearch<'tournament' | 'league' | 'drop-in' | 'combine' | 'practice' | 'clinic'>
            label="Type"
            required
            fullWidth
            value={type}
            options={[
              { label: 'Tournament', value: 'tournament' },
              { label: 'League', value: 'league' },
              { label: 'Drop In', value: 'drop-in' },
              { label: 'Combine/Tryout', value: 'combine' },
              { label: 'Practice', value: 'practice' },
              { label: 'Clinic', value: 'clinic' },
            ]}
            onChange={(newVal) =>
              handleFormChange({
                type: newVal?.value,
                weekDays: ['league', 'drop-in', 'practice', 'clinic'].includes(newVal?.value as string) ? weekDays : [],
              })
            }
          />
          {formErrors.type && <Error>{formErrors.type}</Error>}
        </div>
        <div className={`${styles.FieldWrapper} formErrors.${startDate || formErrors.endDate ? 'error-field' : ''}`}>
          <SpanLabel>
            Dates
            <Required />
          </SpanLabel>
          <DateRangeInput
            startDate={startDate}
            endDate={endDate}
            onChange={(startDate, endDate) => handleFormChange({ startDate, endDate })}
          />
          {formErrors.startDate && <Error>{formErrors.startDate}</Error>}
          {formErrors.endDate && <Error>{formErrors.endDate}</Error>}
        </div>

        {!isFromOrg && (
          <div className={`${styles.FieldWrapper} ${formErrors.organizationId ? 'error-field' : ''}`}>
            <SelectSearch<number>
              label="Organization"
              fullWidth
              search
              searchPlaceholder="Search for an organization"
              value={organizationId || undefined}
              options={[{ label: 'No associated organization' }, ...organizationList]}
              onChange={(newVal) => handleFormChange({ organizationId: newVal?.value })}
            />
            {setShowOrgForm && (
              <button className={styles.AddOrgBtn} onClick={setShowOrgForm}>
                Not finding your organization? Click here to add one
              </button>
            )}
            {formErrors.organizationId && <Error>{formErrors.organizationId}</Error>}
          </div>
        )}

        <div className={`${styles.FieldWrapper} ${formErrors.organizationId ? 'error-field' : ''}`}>
          <SelectSearch<number>
            label="Co-host Organization"
            fullWidth
            search
            searchPlaceholder="Search for an organization"
            value={coHostOrganizationId || undefined}
            options={[{ label: 'No associated co-host organization' }, ...organizationList]}
            onChange={(newVal) => handleFormChange({ coHostOrganizationId: newVal?.value })}
          />
          {setShowOrgForm && (
            <button className={styles.AddOrgBtn} onClick={setShowOrgForm}>
              Not finding your organization? Click here to add one
            </button>
          )}
        </div>

        {type && ['league', 'drop-in', 'practice', 'clinic'].includes(type) && (
          <div className={`${styles.FieldWrapper} ${formErrors.weekDays ? 'error-field' : ''} ${styles.fullWidth}`}>
            <SpanLabel>
              Week days
              {!endSameStartDate && <Required />}
            </SpanLabel>
            <Note>
              Select the days of the week your event is happening. We will create a recurring event until the end date.
            </Note>
            <DayPicker value={weekDays || []} onChange={(newVal) => handleFormChange({ weekDays: newVal })} />

            {formErrors.weekDays && <Error>{formErrors.weekDays}</Error>}
          </div>
        )}
        <div className={`${styles.FieldWrapper} ${formErrors.divisions ? 'error-field' : ''}`}>
          <MultiSelectSearch<number>
            label="Divisions"
            required
            search
            placeholder="Women, Men, Co-ed"
            searchPlaceholder="Search for a division"
            note={isFromOrg ? '' : 'Select all the divisions for your event'}
            fullWidth
            value={divisions}
            options={divisionList as any}
            onChange={(newVal) => handleFormChange({ divisions: newVal?.map((opt) => opt.value) })}
          />
          {formErrors.divisions && <Error>{formErrors.divisions}</Error>}
        </div>
        <div className={`${styles.FieldWrapper} ${formErrors.ballType ? 'error-field' : ''}`}>
          <MultiSelectSearch<number>
            label="Ball Types"
            required
            search
            searchPlaceholder="Search for a ball type"
            placeholder="Foam, Cloth, No-sting"
            note={isFromOrg ? '' : 'Select all the ball types for your event'}
            fullWidth
            value={ballType}
            options={ballTypeList as any}
            onChange={(newVal) => handleFormChange({ ballType: newVal?.map((opt) => opt.value) })}
          />
          {formErrors.ballType && <Error>{formErrors.ballType}</Error>}
        </div>
        <div
          className={`${styles.FieldWrapper} ${formErrors.address ? 'error-field' : ''} ${
            !isFromOrg ? styles.fullWidth : ''
          }`}
        >
          <AddressInput
            label="Location"
            required
            placeholder="Location (type address or city)"
            note={isFromOrg ? '' : 'The address or city of your event'}
            value={address}
            updateValue={(newVal) =>
              handleFormChange({
                address: newVal,
                countryCode: newVal?.countryCode,
                countryName: newVal?.countryName,
                regionCode: newVal?.regionCode,
                regionName: newVal?.regionName,
              })
            }
          />
          {formErrors.address && <Error>{formErrors.address}</Error>}
        </div>
        {!initialData?.contactMethods && (
          <div
            className={`${styles.FieldWrapper} ${formErrors.contactMethods ? 'error-field' : ''} ${styles.fullWidth}`}
          >
            <ContactMethods
              contactMethods={contactMethods}
              updateMethods={(data) => handleFormChange({ contactMethods: data })}
            />
            {formErrors.contactMethods && <Error>{formErrors.contactMethods}</Error>}
          </div>
        )}

        <div className={`${styles.FieldWrapper} ${styles.fullWidth}`}>
          <SpanLabel>Description</SpanLabel>
          <Note>Anything not covered in this form that you want to cover?</Note>
          <Suspense
            fallback={
              <TextareaBlock
                label=""
                value={description || ''}
                updateValue={(newVal) => handleFormChange({ description: newVal })}
              />
            }
          >
            <MarkdownEditor
              height={150}
              text={description || ''}
              onChange={(newVal) => handleFormChange({ description: newVal })}
            />
          </Suspense>
        </div>
        <div className={`${styles.FieldWrapper} ${formErrors.streamUrl ? 'error-field' : ''} ${styles.fullWidth}`}>
          <InputBlock
            label="Streaming URL"
            prefix="https://"
            pasteModifier={stripHttps}
            type="string"
            value={(streamUrl || '').replace('https://', '').replace('http://', '')}
            updateValue={(newVal) => handleFormChange({ streamUrl: addHttps(newVal) })}
          />
          {formErrors.streamUrl && <Error>{formErrors.streamUrl}</Error>}
        </div>
        <div className={`${styles.FieldWrapper} ${formErrors.website ? 'error-field' : ''} ${styles.fullWidth}`}>
          <InputBlock
            label="Website URL"
            prefix="https://"
            pasteModifier={stripHttps}
            note="If your event has a website for more information, add the URL here."
            type="string"
            value={(website || '').replace('https://', '').replace('http://', '')}
            updateValue={(newVal) => handleFormChange({ website: addHttps(newVal) })}
          />
          {formErrors.website && <Error>{formErrors.website}</Error>}
        </div>
        <div className={`${styles.FieldWrapper} ${formErrors.signupUrl ? 'error-field' : ''} ${styles.fullWidth}`}>
          <InputBlock
            label="Signup URL"
            prefix="https://"
            pasteModifier={stripHttps}
            type="string"
            value={(signupUrl || '').replace('https://', '').replace('http://', '')}
            updateValue={(newVal) => handleFormChange({ signupUrl: addHttps(newVal) })}
          />
          {formErrors.signupUrl && <Error>{formErrors.signupUrl}</Error>}
        </div>
        {!userInfo?.email && (
          <div className={`${styles.FieldWrapper} ${styles.fullWidth}`}>
            <div
              className={
                !formErrors.contactConsent ? styles.spacingBottomSmall : `${styles.FieldWrapper} ${styles.fullWidth}`
              }
            >
              <Checkbox
                required
                checked={contactConsent}
                onChange={() => handleFormChange({ contactConsent: !contactConsent })}
                label="I consent to Dodgeball Hub contacting me regarding this event"
              />
              {formErrors.contactConsent && <Error>{formErrors.contactConsent}</Error>}
            </div>
            <Checkbox
              checked={marketingConsent}
              onChange={() => handleFormChange({ marketingConsent: !marketingConsent })}
              label="I would like to opt into Dodgeball Hub contests and news"
            />
          </div>
        )}
      </Fields>
      <div className={styles.ActionsWrapper}>
        <SecondaryBtn onClick={close}>Cancel</SecondaryBtn>
        <MainBtn disabled={uploading} onClick={handleSubmit}>
          {isEditing ? 'Submit Changes' : 'Submit Event'}
        </MainBtn>
      </div>
    </>
  );
};
