import { Tooltip } from '@shared/tooltip';

import styles from './day-picker.module.css';

const DAYS = [
  {
    key: 'sunday',
    label: 'S',
  },
  {
    key: 'monday',
    label: 'M',
  },
  {
    key: 'tuesday',
    label: 'T',
  },
  {
    key: 'wednesday',
    label: 'W',
  },
  {
    key: 'thursday',
    label: 'T',
  },
  {
    key: 'friday',
    label: 'F',
  },
  {
    key: 'saturday',
    label: 'S',
  },
];

export const DayPicker = ({ value, onChange }: { value: string[]; onChange: (newVal: string[]) => void }) => {
  const toggleDay = (key: string) => {
    if (value.includes(key)) {
      onChange(value.filter((k) => k !== key));
    } else {
      onChange([...value, key]);
    }
  };

  return (
    <>
      <div className={styles.Wrapper}>
        {DAYS.map((day) => (
          <Tooltip key={day.key} title={day.key}>
            <button
              className={`${styles.Button} ${value.includes(day.key) ? styles.active : ''}`}
              onClick={() => toggleDay(day.key)}
            >
              {day.label}
            </button>
          </Tooltip>
        ))}
      </div>
    </>
  );
};
